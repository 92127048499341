.sizee {
  font-size: 0.8rem !important;
}

li {
  font-family: "Poppins", sans-serif;
}

label {
  font-family: "Poppins", sans-serif;
}

.sans-serif {
  font-family: "Poppins", sans-serif;
}

input {
  font-family: "Poppins", sans-serif;
}

td {
  font-family: "Poppins", sans-serif;
}

/* In your CSS file */
.address-column {
  max-width: 20rem !important;
  overflow-wrap: break-word;
}
