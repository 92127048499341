.gradient-custom-2 {
    /* fallback for old browsers */
    background: #f0e68c; /* kuning cerah */
  
    /* Chrome 10-25, Safari 5.1-6 */
    background: -webkit-linear-gradient(
      to right,
      #f0e68c, /* kuning cerah */
      #00bfff  /* biru cerah */
    );
  
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background: linear-gradient(
      to right,
      #f0e68c, /* kuning cerah */
      #00bfff  /* biru cerah */
    );
  }
  
.bg-color {
  background-color: #eee;
}

.width-login {
  width: 200px;
}
.width {
  width: 185px;
}
@media (min-width: 768px) {
  .gradient-form {
    height: 100vh !important;
  }
}
@media (min-width: 769px) {
  .gradient-custom-2 {
    border-top-right-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
  }
}
