/* header{
  background-color: #F8FDCF;
} */
/* 
header{
  background-color:#F8FDCF !important  ;
  /* border-color: #78C1F3 !important; */
/* }  */
.custom-image {
    width: 3rem; /* Atur ukuran lebar gambar dalam rem */
    height: 3rem; /* Atur ukuran tinggi gambar dalam rem */
  }