.hoverr1:hover{
    background-color: aqua;
    color: black;
}
.hoverr2:hover{
    background-color: rgb(236, 255, 90);
    color: black;
}
.hoverr3:hover{
    background-color: rgba(255, 88, 88, 0.765);
    color: black;
}