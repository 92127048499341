@import url("https://fonts.googleapis.com/css2?family=Anton&display=swap");
@import url("https://fonts.googleapis.com/css?family=Lato:300");
.images-about {
  width: 100%;
}

.pesan {
  color: black;
}

.pesan:hover {
  background-color: rgb(105, 220, 255) !important;
}
.deskripsi-layanan {
  font-size: clamp(0.5rem, 4vw, 0.8rem);
}

.layanan {
  /* display: grid; */
  grid-gap: 0; /* Menghilangkan gap antar grid */
  row-gap: 0; /* Opsional: Menghilangkan gap antar baris */
  column-gap: 0;
}

.title-service {
  font-size: clamp(1.4rem, 4vw + 0.2rem, 2rem) !important;
}

h3 {
  font-size: clamp(0.8rem, 4vw, 1rem);
  font-family: "Anton", sans-serif;
}

h5 {
  font-size: clamp(0.3rem, 4vw, 1rem);
}

.pembatas {
  border: none; /* Menghilangkan border default */
  height: 1px; /* Tinggi garis */
  background-color: #000; /* Warna garis */
  /* margin: 1.5rem 1rem; */
  width: 100% !important;
}

.img-services {
  width: 12rem !important;
  height: 17rem;
  border-radius: 5%;
  object-fit: cover;
  object-position: center;
}
/* .Layanan{
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
/* } */
.layananPest {
  width: 20rem !important;
  height: 20rem !important;
}
.no-margin-padding {
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.box {
  background: #fff;
  font-family: Montserrat, sans-serif;
  font-size: 24px;
  line-height: 30px;
  font-weight: bold;
  color: #009688;
  padding: 40px;
  box-shadow: inset #009688 0 0 0 5px, inset #059c8e 0 0 0 1px,
    inset #0cab9c 0 0 0 10px, inset #1fbdae 0 0 0 11px, inset #8ce9ff 0 0 0 16px,
    inset #48e4d6 0 0 0 17px, inset #e5f9f7 0 0 0 21px, inset #bfecf7 0 0 0 22px;
  text-shadow: 3px 3px 1px #bfecf7;
}

.headshot {
  flex-shrink: 0;

  border: calc(8px + 0.2vw) solid transparent;
  background-origin: border-box;
  background-clip: content-box, border-box;
  background-size: cover;
  box-sizing: border-box;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.5);
}

.headshot-1 {
  border-radius: 30%;
  background-image: var(--img-url), linear-gradient(#f9f047, #0fd850);
}

.bg-pest {
  background-image: url(../Assets/Image/AboutUs/PestControl/6187.svg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

}
.text-family {
  font-family: fantasy;
}

@media (max-width: 576px) {
  .layananPest {
    width: 7rem !important;
    height: 7rem !important;
  }
  .title-layanan {
    font-size: 1.1rem !important;
  }
}
