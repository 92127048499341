/* main.css */

.text-family {
  font-weight: bolder;
  font-family: 'Courier New', Courier, monospace !important;
}

div.cnt {
  background-color: #9be8d8;
}

/* img.custom-img {
  width: 16rem;
  height: 8rem;
  object-fit: cover;
  object-position: center;
} */

h1.name-cv {
  font-size: clamp(0.8rem, 3.5vw + 0.3rem, 2rem) !important;
  font-family: Impact, Charcoal, sans-serif;
  letter-spacing: 1.6px;
  word-spacing: 1.4px;
  color: black;
  font-weight: normal;
  text-decoration: none solid rgb(250, 250, 250);
  font-style: normal;
  font-variant: small-caps;
  text-transform: uppercase;
}

div.widt {
  /* width: 100vw; */
  height: auto !important;
  /* background-color: rebeccapurple; */
}

div div h2.title-services {
  font-size: 3rem !important;
}

div p.name-deskripsi {
  font-size: clamp(1rem, 2vw + 0.2rem, 1.6rem) !important;
}

div.blobe {
  width: 20%;
  z-index: -1111;
  opacity: 0.5;
  top: 10%;
  left: 15%;
}

h2.title {
  color: black;
  font-size: clamp(1.4rem, 4vw + 0.2rem, 2rem) !important;
}

/* div.list-services div div p{
  font-size: clamp(0.5rem, 2vw, 1.2rem) !important;
} */

h4.title-services {
  color: black;
  font-size: clamp(0.8rem, 4vw + 0.2rem, 1.5rem) !important;
  text-decoration: underline !important;
}

p.deskripsi-services {
  color: black;
  font-size: clamp(0.5rem, 3vw + 0.2rem, 1rem) !important;
}

div.section4 {
  padding-bottom: 3rem;
}

h2.social-media-title {
  color: black;
  font-size: clamp(1.4rem, 4vw + 0.2rem, 2rem) !important;
}

.pembatas {
  border: none; /* Menghilangkan border default */
  height: 1px !important; /* Tinggi garis */
  background-color: rgb(0, 0, 0) !important; /* Warna garis */
  /* margin: 1.5rem 1rem; */
  width: auto;
}

h1.title-galery {
  font-size: clamp(1.4rem, 4vw + 0.2rem, 2rem) !important;
}

.colorPestControl {
  color: #440ff3 !important;
}

.liElement {
  list-style: none;
  list-style-type: none;
}
@media (min-width: 1) {
  .pembatas {
    margin: 1.5rem 1rem;
  }
}

@media (min-width: 400px) and (max-width: 768px) {
  .youtube,
  .whatsapp {
    margin-top: 2rem;
  }
}

@media (min-width: 768) {
  .pembatas {
    margin: 1.5rem 1rem;
  }
}
