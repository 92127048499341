.custom-navbar-nav .nav-link {
  color: black;
  font-weight: 400;
}

a.nav-link.active {
  text-decoration-color: #78c1f3 !important;
  color: #0086df !important;
}

.nav-link {
  color: black !important;
}

div ul li a {
  font-size: clamp(0.5rem, 4vw + 1rem, 1.2rem) !important;
}
.nav-item {
  position: relative;
}

.nav-item::before {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #000;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}

.nav-item:hover::before {
  visibility: visible;
  width: 100%;
}

@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-30%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.dropdown-menu {
  opacity: 0;
  transform: translateY(-50%);
  transition: opacity 0.3s ease-out, transform 0.3s ease-out;
}
.dropdown-menu.show {
  position: absolute;
  top: 2.3rem;
  left: -8rem;
  transform: translateY(0);
  animation: slideDown 0.3s ease-out forwards;
}

.custom-dropdown-menu {
  position: absolute !important;
  padding-left: 1rem !important;
  top: 2.5rem !important;
  left: 0rem !important;
  transform: translateY(0) !important;
  animation: slideDown 0.3s ease-out forwards;
}

.custom-person-dropdown {
  position: absolute;
  top: 2.3rem;
  left: -8rem !important;
  transform: translateY(0);
  animation: slideDown 0.3s ease-out forwards;
}
