
.section5-galery {
  /* display: flex; */
  flex-wrap: wrap;
  justify-content: center !important;
  align-items: center;
}

.image-container img{
  aspect-ratio: 1.5 / 2;
  width: 15rem !important;
  height: auto;
  margin-inline: 0.3rem;
}
